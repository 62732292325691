const countrySelectorEl = document.getElementById('id_present_country_of_residence');
let countySelectorParentEl = null;

if (countrySelectorEl !== null) {
    countySelectorParentEl = document.getElementById('id_county').parentNode.parentNode;
}


const showCounties = () => {
    countySelectorParentEl.className = 'form-group';
};


const hideCounties = () => {
    countySelectorParentEl.className = 'hidden';
};



const checkSelectedCounty = () => {
    if (countrySelectorEl.value === 'GB') {
        showCounties();
    } else {
        hideCounties();
    }
};


const init = () => {
    if (countrySelectorEl === null) {
        return;
    }

    checkSelectedCounty();

    countrySelectorEl.addEventListener('change', (e) => {
        checkSelectedCounty();
    })
};


init();
