import $ from "jquery";
import 'owl.carousel';

$('.owl-carousel-blog').owlCarousel({
    loop:true,
    margin:30,
    nav:true,
	dots:true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:2
        },
        1000:{
            items:2
        }
    },
	onInitialized : function(event){

	  $('.owl-carousel-blog').find('.owl-dots').before(
	      '<button type="button" name="Previous" class="owl-cutom-left">‹</button>');
	  $('.owl-carousel-blog').find('.owl-dots').after(
	      '<button type="button" name="Next" class="owl-cutom-right">›</button>');
	  $('.owl-cutom-left').next(".owl-cutom-left").remove();
	  $('.owl-cutom-right').next(".owl-cutom-right").remove();
	}
});


$(".owl-cutom-left").on("click",function(){
	$(this).parents(".owl-carousel").find(".owl-prev").click();
});

$(".owl-cutom-right").on("click",function(){
	$(this).parents(".owl-carousel").find(".owl-next").click();
});

