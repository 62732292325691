import $ from "jquery";

$(".btn-refine").on("click",function(){
	if($(this).hasClass("active")){
		$(this).removeClass("active");
		$(this).parents(".card").find(".media-filter").slideUp(300);
	} else {
		$(this).addClass("active");
		$(this).parents(".card").find(".media-filter").slideDown(300);
	}
});


function refreshUKLocation($country) {
  const $location = $country
    .closest('.search_form--refine')
    .find('select[name="uk_location"]')
    .first()
    .closest('.media-body')
  ;

  // Toggle the uk location field, and empty it when hidden
  if ($country.val() == "uk") {
    $location.show();
  } else {
    $location.val('');
    $location.hide();
  }
}

$('.search_form--refine select[name="country"]').on('change', e => {
  refreshUKLocation($(e.target));
}).each((i, obj) => {
  refreshUKLocation($(obj));
});
