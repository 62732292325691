/*
** Internal tracker
*/

const trackerUrlRoot = '/t/';

const getTrackerUrl = (pk) => {
    const cb = (new Date()).getTime();
    return `${trackerUrlRoot}${pk}?c=${cb}`;
};

const ping = (pk) => {
    (new Image()).src = getTrackerUrl(pk);
};

// Impression tracker
(() => {
    // If GA is not already loaded, create dummy GA object
    const PG = window.PG;
    if (!PG) {
        return;
    }

    // If there's an impression tracker
    if (PG.pg_it) {
        ping(PG.pg_it);
    }
})();

// Click tracker
$(document).on('click', 'a', (e) => {
    // Find tracker pk
    const $link = $(e.currentTarget);
    const trackerPk = $link.data('tracker');
    if (trackerPk) {
        // Find link target and build tracker url
        const target = $link.attr('target');
        const trackerUrl = trackerUrlRoot + trackerPk;

        if (navigator.sendBeacon) {
            // Beacons are available
            navigator.sendBeacon(trackerUrl);
        } else if (target && !target.match(/^_(self|parent|top)$/)) {
            // Opening in a new window, no problem - just ping with image
            ping(trackerPk);
        } else {
            const client = new XMLHttpRequest();
            client.open('POST', trackerUrl, false); // synchronous
            client.setRequestHeader('Content-Type', 'text/plain;charset=UTF-8');
            client.send();
        }
    }
});
