import MicroModal from 'micromodal';

$(() => {
    if (!$.cookie) {
        return;
    }

    // Disable modal on mobile layout
    if ($(window).width() < 768) {
        return;
    }

    if (!$.cookie('hideSignupModal')) {
        if ($('body').hasClass('signup')) {
            $.cookie('hideSignupModal', 1, { path: '/' });
        } else {
            setTimeout(function () {
                MicroModal.show('signupModal',{
                    onClose: function(modal){
                        $.cookie('hideSignupModal', 1, { path: '/' });
                    }
                });
                $('#signupModal .ok').click(function (e) {
                    window.location.href = $(this).data('url')
                });
            }, 60000);
        }
    }
});
