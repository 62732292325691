/*
** Google analytics
*/

// Standard tag modified for loading at the bottom of the page
const modifyTag = function() {
    // If GA is not already loaded, create dummy GA object
    const PG = window.PG;
    const ga = window.ga || (() => { (ga.q = ga.q || []).push(arguments); });
    if (!PG) {
        return;
    }

    ga.l = 1 * new Date();
    window.GoogleAnalyticsObject = 'ga';

    // Create tracker and log the pageview
    ga('create', PG.ga_id, {
      cookieFlags: 'max-age=7200;secure;samesite=none'
    });
    ga('send', 'pageview');
};
modifyTag();