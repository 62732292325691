import $ from "jquery";
import 'select2/dist/js/select2.min.js';

$(window).on("load resize",function(){
	var widnowWidth = $(window).width();

	if(widnowWidth > 991){
		if($(".navnar-nav").hasClass("active")){
			$(".pmd-sidebar-overlay").hide();
			$(".navnar-nav").show().removeClass("active");
		}
	} else { }

});

$(".btn-menu-toggle").on("click",function(){
	if($(".navnar-nav").hasClass("active")){
		$(".navnar-nav").removeClass("active");
		$(".pmd-sidebar-overlay").fadeOut();
	} else {
		$(".navnar-nav").addClass("active");
		$(".pmd-sidebar-overlay").fadeIn();
	}
});

$(".pmd-sidebar-overlay").on("click",function(){
	$(".navnar-nav").removeClass("active");
	$(".pmd-sidebar-overlay").fadeOut();
});


$(".btn-browse").on("click",function(){
	if($(this).hasClass("active")){
		$(this).removeClass("active");
		$(this).parents(".card-filter").find(".card-footer").slideUp(300);
	} else {
		$(this).addClass("active");
		$(this).parents(".card-filter").find(".card-footer").slideDown(300);
	}
});

$(".search_dept-courses .btn-view-more").on("click",function(){
  const $ul = $(this).prev("ul");
	if($(this).hasClass("active")){
		$(this).html("View more");
		$(this).removeClass("active");
		var listsmallHeight = $ul.attr("data-height");
		$ul.animate({ 'height' : listsmallHeight },300)
		$ul.addClass("courses-list-hide");
	} else {
		$(this).html("View less");
		$(".courses-list").each(function(){
			if(!$(this).hasClass('courses-list-hide')){
				var listsmallHeight = $(this).attr("data-height");
				$(this).animate({ 'height' : listsmallHeight },300)
				$(this).addClass("courses-list-hide");
				$(this).next(".btn-view-more").removeClass("active");
			}
		});

		$ul.removeAttr("style").removeAttr("data-height");
		var listHeight = $ul.outerHeight();
		$ul.removeClass("courses-list-hide");
		var listFullHeight = $ul.outerHeight();

		$ul.css("height",listHeight).attr("data-height",listHeight);

		$(this).addClass("active");
		$ul.animate({ 'height' : listFullHeight },300)
	}
});

$(".btn-view-courses").on("click",function(){
	if($(this).hasClass("active")){
		$(this).removeClass("active");
		$(this).next(".courses-list").slideUp();
	} else {
		$(".btn-view-courses").removeClass("active");
		$(".courses-list").slideUp();
		$(this).addClass("active");
		$(this).next(".courses-list").slideDown();
	}
});

// In your Javascript (external .js resource or <script> tag)
$(document).ready(function() {
    $('.select2-basic').select2();
});


// This needs to move to form_extra.js, but currently has to be defined in jQuery scope
const country = document.getElementById('id_present_country_of_residence');
const county = document.getElementById('id_county')?.closest('.form-group');

const updateCounty = e => {
  if (country.value == 'UK' || country.value == 'GB') {
    county.classList.remove('hidden');
  } else {
    county.classList.add('hidden');
  }
};

// Select2 events only trigger jquery, not native events
if (country && county) {
  //country.addEventListener('change', updateCounty);
  $(country).on('change', updateCounty);
  updateCounty();
}
